const elem = {

    menu:        document.getElementById('menu'),
    msg:         document.getElementById('msg'),
    nav:         document.getElementById('nav'),
    exif:        document.getElementById('exif'),
    msgText:     document.getElementById('msg-text'),
    
    icons:      {
        menu:   document.getElementById('menu-icon'),
        toggle: document.getElementById('toggle-icon')
    },

    sections:   {
        photos:  document.getElementById('photos'),
        map:     document.getElementById('map'),
        about:   document.getElementById('about'),
        other:   document.getElementById('other'),
    },

    exifValues: {
        date:    document.getElementById('exif-date'),
        suburb:  document.getElementById('exif-suburb'),
        lat:     document.getElementById('exif-lat'),
        long:    document.getElementById('exif-long'),
        shutter: document.getElementById('exif-shutter')
    }

};
