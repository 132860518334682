elem.msgText.innerHTML = 'Loading photos...';

// ChildNode.remove() polyfill
if (!('remove' in Element.prototype)) {
    Element.prototype.remove = function () {
        this.parentNode && this.parentNode.removeChild(this);
    };
}

(function () {
    function loadScript(src, done) {
        const script = document.createElement('script');
        script.src = 'scripts/' + src;
        done && (script.onload = done);
        script.onerror = () => {
            elem.msgText.innerHTML = 'Browser error!';
        };
        document.body.appendChild(script);
    }

    if (!Array.from || !Array.includes || !Symbol) {
        loadScript('polyfill.min.js', () => {
            loadScript('msa.min.js');
        });
    } else {
        loadScript('msa.min.js');
    }
})();
